const id_master_goods = {
	add: "tambah barang",
	edit: "ubah barang",
	price: "harga barang",
	upload: "unggah barang",
	barcode: "barcode",
	// barcode: "cetak barcode",
	view: "detail barang",
	access_chasier: "akses kasir",
	access_request: "akses permintaan",
}

export default id_master_goods
