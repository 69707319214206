export const ACC_TKN = "indo5_dist_cg_acc_tkn"
export const RFSH_TKN = "indo5_dist_cg_rfsh_tkn"
export const DOMAIN = import.meta.env.VITE_DOMAIN
export const DOMAIN_1TOKO = import.meta.env.VITE_DOMAIN_1TOKO

export const FIRSTDAY_OF_MONTH = new Date(new Date().getFullYear(), new Date().getMonth(), 1)

// regex
export const regexNumber = /^([0-9])+$/
export const regexNumber2 = /^([0-9 +\-\(\)])+$/
export const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z0-9~!@#$%^&-_+=]{6,}$/
export const phoneRegex = /^$|^[^0\D][0-9]{0,13}$/

// key
export const googleMapsKey = "AIzaSyB3YX-dK1z_Cv4GLRMfOoJEVVPHybkmFfg"

// param
export const costMethodList = [{ id: 1, name: "%" }, { id: 2, name: "cash" }]
export const soTypeList = [{ id: 1, name: "Tanpa PPN" }, { id: 2, name: "Dengan PPN" }]
export const tdSalesList = [{ id: 0, name: "Bukan Outlet" }, { id: 1, name: "Outlet" }]
